import { useLoaderData } from 'react-router-dom';
import LastUpdate from '../SecantComponents/SharedComponents/LastUpdate';
import EventReport from "./../../../EventReports/EventReport";

import { useEffect, useState } from 'react';

import '../SecantComponents/MainData.css'
import './Alerts.css'

function Alerts() {
    const AlertsData = useLoaderData();
    const [hideStatus, setHideStatus] = useState({ status: true, data: null });


    const refreshStatus = localStorage.getItem("refresh");
    useEffect(() => {
        if (refreshStatus === "on") {
            const interval = setInterval(() => {
                window.location.reload(false);
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [refreshStatus]);

    return (
        <main className="main-info">
            <LastUpdate />

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Source</th>
                        <th>Timestamp</th>
                        <th>Details</th>
                    </tr>
                </thead>

                <tbody>
                    {AlertsData.map(entry => {
                        return (
                            <tr key={`${entry.source}-${entry.id}`}>
                                <td>{entry.id}</td>
                                <td>{entry.source}</td>
                                <td>{entry.timestamp}</td>
                                <td><span className="material-symbols-outlined open-report-button" onClick={() => {
                                    setHideStatus({ status: false, data: entry.alert_details ? entry.alert_details : entry.alert_type });
                                }}> visibility </span> </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <EventReport hideStatus={hideStatus} setHideStatus={setHideStatus} title="Details" component="Alerts" />
        </main>
    )
}

export default Alerts


export async function loader({ request }) {

    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    const Response = await fetch(`${baseURL}/api/alerts/all`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    if (!Response.ok) {
        // TODO: Handle the rerror
    }
    const responseData = await Response.json();
    // const responseData = [
    //     {
    //         "id": 1,
    //         "alert_type": "Cocrae risk assessment finished successfully",
    //         "source": "Cocrae",
    //         "timestamp": "2024-10-12T13:53:49.761117Z"
    //     },
    //     {
    //         "id": 2,
    //         "alert_type": "Cocrae risk assessment finished successfully",
    //         "source": "Cocrae",
    //         "timestamp": "2024-10-12T16:09:51.754597Z"
    //     },
    //     {
    //         "id": 4,
    //         "alert_details": {
    //             "content": {
    //                 "id": "1691055465.8021",
    //                 "data": {
    //                     "flow": {
    //                         "start": "2023-08-03T09:37:44.170025+0000",
    //                         "pkts_toclient": "4",
    //                         "pkts_toserver": "4",
    //                         "bytes_toclient": "378",
    //                         "bytes_toserver": "299"
    //                     },
    //                     "http": {
    //                         "length": "106",
    //                         "http_port": "0",
    //                         "http_method": "MGLNDD_195.82.130.168_8086"
    //                     },
    //                     "alert": {
    //                         "gid": "1",
    //                         "rev": "1",
    //                         "action": "allowed",
    //                         "category": "Generic Protocol Command Decode",
    //                         "severity": "3",
    //                         "signature": "SURICATA Applayer Detect protocol only one direction",
    //                         "signature_id": "2260002"
    //                     },
    //                     "proto": "TCP",
    //                     "src_ip": "10.129.150.177",
    //                     "dest_ip": "0.0.0.0",
    //                     "flow_id": "1751824640546857.000000",
    //                     "in_iface": "ens160",
    //                     "metadata": {
    //                         "flowints": {
    //                             "applayer": {
    //                                 "anomaly": {
    //                                     "count": "1"
    //                                 }
    //                             }
    //                         }
    //                     },
    //                     "src_port": "39968",
    //                     "app_proto": "http",
    //                     "dest_port": "8086",
    //                     "timestamp": "2023-08-03T09:37:44.566667+0000",
    //                     "event_type": "alert",
    //                     "app_proto_ts": "failed",
    //                     "community_id": "1:d2iofx62J4/9UK6UVt0hAhd4PSo="
    //                 },
    //                 "rule": {
    //                     "id": "86601",
    //                     "mail": false,
    //                     "level": 3,
    //                     "groups": [
    //                         "ids",
    //                         "suricata"
    //                     ],
    //                     "firedtimes": 1,
    //                     "description": "Suricata: Alert - SURICATA Applayer Detect protocol only one direction"
    //                 },
    //                 "agent": {
    //                     "id": "002",
    //                     "ip": "0.0.0.0",
    //                     "name": "secant"
    //                 },
    //                 "input": {
    //                     "type": "log"
    //                 },
    //                 "decoder": {
    //                     "name": "json"
    //                 },
    //                 "manager": {
    //                     "name": "secant-2"
    //                 },
    //                 "location": "/home/secant/secant_app/suricata-docker/suricata/logs/eve.json",
    //                 "timestamp": "2023-08-03T09:37:45.150+0000"
    //             },
    //             "notificationType": "wazuhEvent",
    //             "triggerComponent": "Wazuh"
    //         },
    //         "source": "Suricata",
    //         "timestamp": "2024-10-16T13:47:26.641743Z"
    //     },
    //     {
    //         "id": 5,
    //         "alert_details": {
    //             "content": {
    //                 "id": "1691055465.8021",
    //                 "data": {
    //                     "flow": {
    //                         "start": "2023-08-03T09:37:44.170025+0000",
    //                         "pkts_toclient": "4",
    //                         "pkts_toserver": "4",
    //                         "bytes_toclient": "378",
    //                         "bytes_toserver": "299"
    //                     },
    //                     "http": {
    //                         "length": "106",
    //                         "http_port": "0",
    //                         "http_method": "MGLNDD_195.82.130.168_8086"
    //                     },
    //                     "alert": {
    //                         "gid": "1",
    //                         "rev": "1",
    //                         "action": "allowed",
    //                         "category": "Generic Protocol Command Decode",
    //                         "severity": "3",
    //                         "signature": "SURICATA Applayer Detect protocol only one direction",
    //                         "signature_id": "2260002"
    //                     },
    //                     "proto": "TCP",
    //                     "src_ip": "10.129.150.177",
    //                     "dest_ip": "0.0.0.0",
    //                     "flow_id": "1751824640546857.000000",
    //                     "in_iface": "ens160",
    //                     "metadata": {
    //                         "flowints": {
    //                             "applayer": {
    //                                 "anomaly": {
    //                                     "count": "1"
    //                                 }
    //                             }
    //                         }
    //                     },
    //                     "src_port": "39968",
    //                     "app_proto": "http",
    //                     "dest_port": "8086",
    //                     "timestamp": "2023-08-03T09:37:44.566667+0000",
    //                     "event_type": "alert",
    //                     "app_proto_ts": "failed",
    //                     "community_id": "1:d2iofx62J4/9UK6UVt0hAhd4PSo="
    //                 },
    //                 "rule": {
    //                     "id": "86601",
    //                     "mail": false,
    //                     "level": 3,
    //                     "groups": [
    //                         "ids",
    //                         "wazuh"
    //                     ],
    //                     "firedtimes": 1,
    //                     "description": "Suricata: Alert - SURICATA Applayer Detect protocol only one direction"
    //                 },
    //                 "agent": {
    //                     "id": "002",
    //                     "ip": "0.0.0.0",
    //                     "name": "secant"
    //                 },
    //                 "input": {
    //                     "type": "log"
    //                 },
    //                 "decoder": {
    //                     "name": "json"
    //                 },
    //                 "manager": {
    //                     "name": "secant-2"
    //                 },
    //                 "location": "/home/secant/secant_app/suricata-docker/suricata/logs/eve.json",
    //                 "timestamp": "2023-08-03T09:37:45.150+0000"
    //             },
    //             "notificationType": "wazuhEvent",
    //             "triggerComponent": "Wazuh"
    //         },
    //         "source": "Wazuh",
    //         "timestamp": "2024-10-16T13:48:07.571712Z"
    //     }
    // ]

    return responseData;
}